.menu-item-has-children > a:after{
  display: inline-block;
  margin-left: .55em;
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  transition: transform 0.4s ease;
}
.menu-item-has-children:hover > a:after{
  transform: rotate(180deg);
}
.nav-item{
  position: relative;
}
/* Accprdion */
.accordion .card {
  display: flex;
  flex-wrap: wrap;
}
.accordion .card .collapseparent {
  order: 2;
}
.accordion .card .card-header {
  width: 100%;
  flex-basis: 100%;
  order: 1;
  padding: 0;
  border-radius: 0;
}
.faqs-accordion .card .card-header .collapsebtn:after {
  content: 'View';
  color: #0070ba;
}
.accordion .card .collapseparent.show+.card-header .collapsebtn:after{
  content: 'Close';
}
.accordion>.card:not(:last-of-type){
  border-bottom: 1px solid #0070ba;
}
.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper .popular_post_img{
  height: 90px;
}
.user-info-tabs .step-app .step-steps > li.go2998626915 > a > .number:before,
.user-info-tabs .step-app .step-steps > li.go2998626915 > a > .number:before{
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #fff;
}
.user-info-tabs .step-app .step-steps > li.go2998626915 > a > .number,
.user-info-tabs .step-app .step-steps > li.go2998626915 > a > .number {
    background-color: #0070ba;
}
.user-info-tabs .step-app .step-steps > li.go2998626915 > a > .step-name,
.user-info-tabs .step-app .step-steps > li.go2998626915 > a > .step-name {
    color: #0070ba;
}
.user-info-tabs .step-app .step-steps > li.go2998626915:before {
  background-color: #0070ba;
}
.extra-page{
  height:100vh;
}
.overlapsec + .sub-header .section-padding{
  padding-bottom: 160px;
}
.user-dashboard-sec .dashboard-first-sec .left-side h5:after{
  display: none;
}
.user-dashboard-sec .dashboard-first-sec .left-side h5 a:after {
  display: inline-block;
  margin-left: .55em;
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #0070ba;
  font-size: 22px;
  line-height: 1.2;
  position: relative;
  top: 2px;
  transform: rotate(180deg);
  transition: 0.3s;
}
.user-dashboard-sec .dashboard-second-sec[aria-expanded="false"]:after, 
.user-dashboard-sec .dashboard-first-sec .left-side h5 a[aria-expanded="false"]:after{
  transform: none;
}
.user-dashboard-sec .dashboard-second-sec[aria-expanded="false"] .right-side{
  opacity: 1;
}
.user-dashboard-sec .dashboard-second-sec-large .left-side .pie-chart-area .active-border,
.user-dashboard-sec .dashboard-second-sec .right-side .pie-chart-box .active-border{
  background: none;
}
.user-dashboard-sec .dashboard-second-sec-large .left-side .pie-chart-area .circle,
.user-dashboard-sec .dashboard-second-sec .right-side .pie-chart-box .circle{
  top: 0;
  left: 0;
  width: auto;
  height: auto;
}
@media (max-width:991px){
  .menu-item-has-children > a{
    display: flex!important;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 460px) {
  .overlapsec + .sub-header .section-padding {
    padding-bottom: 80px;
}
}